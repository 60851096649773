import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { gemtechLogo } from "assets";
import { useMediaQuery } from "react-responsive";

const AboutUsSection = ({ color = "#FFFFFF" }) => {
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <Container
            className={`d-flex flex-column align-items-center py-5`}
            fluid
            style={{ backgroundColor: color }}
        >
            <div className="custom-header mb-3">About Us</div>
            <Container className="my-5">
                <Row className={`${large ? "row-cols-2" : "row-cols-1"}`}>
                    <Col className="about-us p-5" style={{ borderRadius: 20 }}>
                        <div className="d-flex justify-content-center align-items-center" style={{ width: 500, height: 400 }}>
                            <Image src={gemtechLogo} alt="Gemtech Logo" style={{ maxWidth: 200, height: "auto" }} />
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center px-lg-5 mt-lg-0 mt-5">
                        <div className={`subtitle-400 text-center`}>
                            Welcome to Gemtech Solutions, your trusted partner in end-to-end engineering solutions.
                            With a focus on excellence and innovation, we specialize in providing comprehensive
                            consultancy services for turnkey projects across the Pharmaceuticals, Nutraceuticals, and
                            Food industries. Our expertise and dedication ensure that we deliver customized solutions
                            tailored to meet the unique needs of each client, fostering growth and success in their
                            respective fields. At Gemtech Solutions, we are committed to driving progress through
                            cutting-edge engineering and a client-centric approach.
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default AboutUsSection;
