import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MissionAndVision } from "components";
import { aboutCoverImage } from "assets";

const AboutUs = () => {
  const navigate = useNavigate();
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="about-us-hero-background d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "100vh", background: "linear-gradient(-20deg, #eabe21 50%, #ffffff 50%)" }}
      >
        <Container className="py-5 text-center">
          <Image src={aboutCoverImage} className="img-fluid w-100" />
          <Button
            className={`mt-5 text-tertiary border border-tertiary manrope-600 bg-white ${medium ? "" : "w-100"}`}
            style={{
              paddingLeft: 35,
              paddingRight: 35,
              borderRadius: 34,
              height: 60,
            }}
            onClick={() => navigate(`/contact-us`)}
          >
            Contact Us
          </Button>
        </Container>
      </Container>
      <MissionAndVision />
    </Container>
  );
};

export default AboutUs;
