import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { granulationRapid, granulationFluid, granulationDoubleConeBlender, granulationRollVibroSifter, granulation } from "assets";
import { SolutionCard } from "components";

const Granulation = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const [currentSolution, setCurrentSolution] = useState(null);

    const elements = [
        { id: 1, title: "Rapid Mixer Granulator", slug: "rapid-mixer-granulator", image: granulationRapid },
        { id: 2, title: "Fluid Bed Processor", slug: "fluid-bed-processor", image: granulationFluid },
        { id: 3, title: "Blender", slug: "blender", image: granulationDoubleConeBlender },
        { id: 4, title: "Vibro Sifter", slug: "vibro-sifter", image: granulationRollVibroSifter },
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={granulation}
                    alt="Granulation"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Granulation</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container
                fluid
                className="my-5 pt-5 bg-white"
                style={{ minHeight: "50vh" }}
            >
                <Container>
                    <Row>
                        {elements.map((item) => (
                            <Col className="pb-4" lg={4}>
                                <SolutionCard
                                    solution={item}
                                    currentSolution={currentSolution}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default Granulation;
