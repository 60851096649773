import { aim, mission, missionAndVisionIcon, vision } from "assets";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MissionAndVision = () => {

  return (
    <Container fluid className="bg-white py-5">
      <Container>
        <Row>
          <Col lg={4} className="p-0 bg-secondary d-flex flex-column justify-content-center">
            <h3 className="px-5 pt-lg-0 pt-5">Our Mission</h3>
            <p className="pt-lg-3 pb-lg-0 pb-3 px-5">
              Our mission is to empower clients with superior engineering solutions that enhance
              efficiency and drive sustainable growth. We strive to exceed expectations with technical
              expertise, innovation, and a commitment to quality.
            </p>
          </Col>
          <Col lg={4} className="p-0">
            <div>
              <Image className="w-100" src={mission} alt="image" />
            </div>
          </Col>
          <Col lg={4} className="p-0 bg-secondary d-flex flex-column justify-content-center">
            <h3 className="px-5 pt-lg-0 pt-5">Our Vision</h3>
            <p className="pt-lg-3 pb-lg-0 pb-3 px-5">
              Our vision is to be the leading provider of engineering consultancy services, known
              for excellence and innovation. We aim to set industry standards and foster continuous improvement
              and collaboration for our clients' success.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="p-0">
            <Image className="w-100" src={vision} alt="image" />
          </Col>
          <Col lg={4} className="p-0 bg-secondary d-flex flex-column justify-content-center">
            <h3 className="px-5 pt-lg-0 pt-5">Our Aim</h3>
            <p className="pt-lg-3 pb-lg-0 pb-3 px-5">
              Our aim is to provide integrated engineering solutions that drive client success. We address
              the unique challenges of the Pharmaceuticals, Nutraceuticals, and Food industries with precision,
              delivering projects that exceed expectations and ensure long-term sustainability.
            </p>
          </Col>
          <Col lg={4} className="p-0">
            <Image className="w-100" src={aim} alt="image" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default MissionAndVision;
