import React from "react";
import { Container, Image } from 'react-bootstrap';
import { gemtechLoader } from "assets";

const Loader = () => {
    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
            style={styles.parentContainer}
        >
            <Image src={gemtechLoader} width="100" loading="lazy" />
        </Container>
    );
}

const styles = {
    parentContainer: {
        zIndex: 9999,
        top: 0,
        bottom: 0,
        background: "#ffffff",
    }
}

export default Loader;