import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { coatingMachine, coating } from "assets";
import { FeaturePoints, ShareCard } from "components";

const Coating = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Modular design for user-friendly operation.",
        "Accurate Temperature Control within ± 1°C for precise coating.",
        "In-built WIP (Wash in Place) system with a pump for easy cleaning.",
        "Easy to clean and maintain, reducing changeover time.",
        "Quick-release plenums facilitate effortless cleaning of the pan and plenums.",
        "Leak-proof sealing of doors ensures efficient operation without energy loss.",
        "Fully automatic operation through PLC with a color HMI for streamlined control.",
        "3 Stage AHU with D.P. Gauge and Puff insulation for optimized air handling.",
        "21 CFR Part 11 compliance available on request for regulatory adherence."
    ]

    const optionalEnhancements = [
        "Sugar dosing system for precise control over coating ingredients.",
        "Interchangeable pan system for handling a variety of batch sizes in one machine.",
        "Flameproof design for added safety in specific environments.",
        "Dry or wet scrubber at exhaust for efficient air handling.",
        "Inlet and outlet duct for optimized airflow.",
        "Interchangeable drum for flexibility in handling different batch sizes.",
        "Spray nozzle for tablet coating with reduced surface area to prevent bearding.",
        "Automatic charging and discharging of tablets for ease of operation (Optional).",
        "IR Sensor for accurate tablet bed temperature control (Optional).",
        "Inflatable gaskets to seal off the process chamber.",
        "Swivel arm for ease of operation.",
        "Sugar coating attachment for added flexibility (Optional).",
        "MHMD Pump for a uniform spray rate from all the nozzles."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={coating}
                    alt="Tablet Coating"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Tablet Coating</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "ms-4" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <Row className="my-lg-5 pt-lg-5">
                        <Col sm={12} md={6} lg={4}>
                            <Image
                                className="w-100 border"
                                src={coatingMachine}
                                alt="Tableting Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={false} />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                Our tablet-coating machines can handle a variety of coating applications, including
                                film, seal, sugar, and enteric coating. Our range of machines are from R&D scale to
                                large-scale production. Innovative features of our coating machine, such as spray nozzle
                                and baffle design, it helps to ensure a faster and efficient coating process.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The Tablet Coater is meticulously designed with key features aimed at enhancing
                                productivity and flexibility in the tablet coating process. Its user-friendly operation
                                and adherence to high safety standards make it a reliable solution that consistently delivers
                                results with the utmost quality and efficiency.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Enhancements :</h3>
                    <FeaturePoints items={optionalEnhancements} />
                </Container>
            </Container>
        </>
    );
};

export default Coating;
