import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, weighMetricOilFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const WeighMetricOilFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Filling Range: 500ml to 5 litres & 2 to 15 litres & Kg.",
        "Sleek and elegant matte finished body.",
        "Nozzle Type: Diving nozzle with positive cut-off, ensuring precise filling.",
        "Accuracy of Filling: +/- 0.25% of the filled volume, guaranteeing precision.",
        "Volume Setting: Easily adjustable on the HMI with a 7-inch LCD display for user convenience.",
        "Mitsubishi Make PLC & HMI, ensuring reliable performance.",
        "No Container No Fill: Non-contact sensors facilitate a foolproof No Container No Fill System arrangement.",
        "Models: Available in 4, 6, and 8 head configurations, catering to various production requirements.",
        "Drip-arresting tray: Positioned below the nozzle to catch accidental drips, maintaining cleanliness and preventing wastage."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Weigh Metric Oil Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-lg-5 pt-lg-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Weigh Metric Oil Filling
                        </span>
                    </div>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={weighMetricOilFilling}
                                alt="Weigh Metric Oil Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                The Automatic Weigh Metric Oil Filling Machine stands out as an ideal choice for
                                filling edible oil, jars, tins, and other free-flowing materials, either by volume
                                or weight. The weight is meticulously controlled by a load cell paired with a
                                microprocessor programmable system, ensuring exceptional accuracy in the feeding
                                process. This versatile machine accommodates a filling range from 500 ml to 5
                                liters, with higher capacity models also readily available.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default WeighMetricOilFilling;
