import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, singleHeadTubeFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const SingleHeadTubeFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Versatile filling capacity: Capable of filling from 5 gm to 200 gm of cream/ointment.",
        "Tube sealing/closing: Accommodates Lami tubes up to 40 mm diameter and aluminium tubes up to 28 mm diameter.",
        "Oval race track with aluminium tube holder for precise handling during the filling process.",
        "Fully enclosed body: The machine features a fully enclosed body for enhanced safety and cleanliness, if required.",
        "Premium construction: Cladded with pre-buffed SS sheet for a durable and aesthetic exterior.",
        "Overload clutch protection: Ensures conveyor safety and prevents overloading.",
        "Barcode reading provision: Optional feature for efficient tracking and identification, if required.",
        "Stainless steel construction: All stationary parts above the tabletop are made of SS 304, ensuring hygiene and durability.",
        "Variable frequency drive: Equipped with a variable frequency drive for adjustable machine speed.",
        "Advanced control system: The machine incorporates PLC and touch screen HMI for seamless operation and control.",
        "Hardened cams and bearing support: All cams are hardened, and shafts are supported on bearings for robust performance.",
        "Material quality: Contact parts are made of SS 316, and non-contact parts are made of SS 304, ensuring compatibility and longevity."
    ]

    const optionalFeatures = [
        "Saddle Fold: Allows for the incorporation of a saddle fold feature, enhancing tube presentation and aesthetics.",
        "Multi-color Filling: Enables the capability for multi-color filling, providing flexibility in product presentation.",
        "Designer Seal in Plastic Tubes: Adds the option for designer seals in plastic tubes, enhancing branding and visual appeal.",
        "Gas Flushing Before & After Filling: Incorporates gas flushing before and after filling, contributing to product freshness and shelf life optimization."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Single Head Tube Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-lg-5 pt-lg-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Single Head Tube Filling
                        </span>
                    </div>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={singleHeadTubeFilling}
                                alt="Single Head Tube Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                The Fully Automatic Tube Filling Machine is designed for efficient filling
                                and sealing of tubes, catering to both LAMI and Plastic tube materials.
                                Widely applicable across the Pharmaceutical, Cosmetics, Chemical, Agriculture,
                                and Food industries, this machine is versatile and capable of handling various
                                materials, including Cosmetic Creams, Pastes, Honey, Gel, Gum, Balm, Ointments,
                                and more.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The machine's seamless operation encompasses tube feeding, filling, sealing, and
                                coding (manufacturing date, expiry, lot number, etc.) in a single, indexed operation.
                                Suitable for LAMI, Plastic, and Aluminium tubes, it provides a comprehensive
                                solution for diverse tube-filling needs.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Our product line includes both fully automatic and semi-automatic tube filling
                                machines, accommodating high viscosity materials such as paint, adhesive, cosmetics,
                                lotion, toothpaste, shampoo, and more. This machine stands as a reliable and
                                versatile solution for a wide range of tube-filling applications.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Features :</h3>
                    <FeaturePoints items={optionalFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default SingleHeadTubeFilling;
