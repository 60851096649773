import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";


const ContactUsForm = () => {
  const form = useRef();

  return (
    <Form ref={form}>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">Full Name</Form.Label>
        <Form.Control
          name="full_name"
          placeholder="Enter your Full Name"
          type="text"
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">Email</Form.Label>
        <Form.Control
          name="email"
          placeholder="Enter your Email"
          type="text"
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">Phone</Form.Label>
        <Form.Control
          name="phone"
          placeholder="Enter your Phone Number"
          type="text"
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">Message</Form.Label>
        <Form.Control
          name="message"
          as="textarea"
          rows={5}
          placeholder="Leave Us a Message"
          type="text"
          className="bg-white p-4"
          style={{
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Check
          type="checkbox"
          required
          label="You agree to our friendly privacy policy."
        />
      </Form.Group>
      <Button
        className="my-4 bg-primary text-white primary-button manrope-600 w-100"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 30,
          height: 60,
        }}
        type="submit"
      >
        Send
      </Button>
    </Form>
  );
};

export default ContactUsForm;
