import React from "react";
import { Container, Image, Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { granulation, granulationRollVibroSifter } from "assets";
import { FeaturePoints, ShareCard, CardBulletPoint } from "components";
import { useMediaQuery } from "react-responsive";


const VibroSifter = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const vibroSifterData = [
        {
            id: 1,
            title: "Gyratory Vibrations",
            description: (
                <ul className="ps-4 mb-2">
                    <li className="text-start">
                        The machine utilizes gyratory vibrations to separate materials.
                    </li>
                    <li className="text-start">
                        When the motor is energized, it induces vibrations in the screen or sieve.
                    </li>
                </ul>
            )
        },
        {
            id: 2,
            title: "Particle Size Separation",
            description: (
                <ul className="ps-4 mb-2">
                    <li className="text-start">
                        Material travels across the sieves according to its particle size in response to the vibratory motion.
                    </li>
                    <li className="text-start">
                        The sieves are designed to allow only particles of a certain size to pass through.
                    </li>
                </ul>
            )
        },
        {
            id: 3,
            title: "Three-Plane Vibration",
            description: (
                <ul className="ps-4 mb-2">
                    <li className="text-start">
                        The machine generates vibrations along three different planes.
                    </li>
                    <li className="text-start">
                        This is achieved using a specially designed vibratory motor to meet various output requirements.
                    </li>
                </ul>
            )
        },
        {
            id: 4,
            title: "Rotary Motion and Dispersion",
            description: (
                <ul className="ps-4 mb-2">
                    <li className="text-start">
                        The vibratory motor causes 100% rotary motion, leading to the dispersion, stirring, and stratification of the material to sift.
                    </li>
                </ul>
            )
        },
        {
            id: 5,
            title: "Horizontal Movement",
            description: (
                <ul className="ps-4 mb-2">
                    <li className="text-start">
                        The feed material flows horizontally along with a loop pattern on the Vibro screen.
                    </li>
                </ul>
            )
        },
    ]

    const keyFeatures = [
        "All contact parts are made of stainless steel (SS)",
        "Designed for quick and easy dismantling of contact parts for cleaning without hassle",
        "Portable, mounted on sturdy PU wheels with SS 304 for convenient mobility",
        "Silicon-molded SS 316 screen (40 mesh) with a viewing glass"
    ]

    const optionalEnhancements = [
        "Anti-blinding arrangement for enhanced performance",
        "Silicon/Teflon balls for specific applications",
        "Motor mounting plate for added stability",
        "Magnetic grill separator at charging/discharging port for material purity",
        "Double and triple deck options available for customized use",
        "Air vent for improved ventilation"
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={granulation}
                    alt="Granulation"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Granulation</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Blender</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-lg-5 pt-lg-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/granulation">
                            Granulation
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Vibro Sifter
                        </span>
                    </div>
                    <Row>
                        <Col sm={12} md={3} lg={3}>
                            <Image
                                className="w-100"
                                src={granulationRollVibroSifter}
                                alt="Vibro Sifter Machine"
                                style={{ borderRadius: 20 }}
                            />
                        </Col>
                        <Col sm={12} md={9} lg={9}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray my-4">
                                The Vibro Sifter machine operates on the principle of gyratory vibrations to effectively separate materials based on their particle size. The Combo-Feeder, an optimised feeding system, introduces several enhancements to the compaction process. Here's how it works:
                            </div>
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={false} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-4 row-cols-2">
                        {vibroSifterData.map((data) => {
                            return (
                                <Col className="mb-3">
                                    <CardBulletPoint data={data} />
                                </Col>
                            );
                        })}
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Enhancements :</h3>
                    <FeaturePoints items={optionalEnhancements} />
                </Container>
            </Container>
        </>
    );
};

export default VibroSifter;
