import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";

import { Navbar, Footer, ScrollToTop } from "components";
import Home from "pages/Home/Home";
import ContactUs from "pages/ContactUs/ContactUs";
import AboutUs from "pages/AboutUs/AboutUs";
// import Blogs from "pages/Blogs/Blogs";
// import Blog from "pages/Blogs/Blog";
import Solutions from "pages/Solution/Solutions";
// Granulation
import Granulation from "pages/Solution/Granulation/Granulation";
import RapidMixerGranulator from "pages/Solution/Granulation/RapidMixerGranulator";
import FluidBedProcessor from "pages/Solution/Granulation/FluidBedProcessor";
import Blender from "pages/Solution/Granulation/Blender";
import VibroSifter from "pages/Solution/Granulation/VibroSifter";
// Liquid Filling
import LiquidFilling from "pages/Solution/LiquidFilling/LiquidFilling";
import VolumetricLiquidFilling from "pages/Solution/LiquidFilling/VolumetricLiquidFilling";
import GearPumpLiquidFilling from "pages/Solution/LiquidFilling/GearPumpLiquidFilling";
import RinsingFillingAndCapping from 'pages/Solution/LiquidFilling/RinsingFillingAndCapping';
import TimeBasedLiquidFilling from 'pages/Solution/LiquidFilling/TimeBasedLiquidFilling';
import WeighMetricOilFilling from 'pages/Solution/LiquidFilling/WeighMetricOilFilling';
import ViscousPneumaticPistonFilling from 'pages/Solution/LiquidFilling/ViscousPneumaticPistonFilling';
import VolumetricLiquidVialFilling from 'pages/Solution/LiquidFilling/VolumetricLiquidVialFilling';
import SingleHeadTubeFilling from 'pages/Solution/LiquidFilling/SingleHeadTubeFilling';
import DoubleHeadTubeFilling from 'pages/Solution/LiquidFilling/DoubleHeadTubeFilling';
import SemiAutomaticTubeFilling from 'pages/Solution/LiquidFilling/SemiAutomaticTubeFilling';

import Tableting from "pages/Solution/Tableting";
import Coating from "pages/Solution/Coating";
import Encapsulation from "pages/Solution/Encapsulation";
import BlisterPackingCartoning from "pages/Solution/BlisterPackingCartoning";
import ChangeParts from "pages/Solution/ChangeParts";


const Routers = () => {

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <Router>
      <Container fluid className="px-0 manrope">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="about-us" element={<AboutUs />} />
          {/* <Route path="blogs" element={<Blogs />}>
            <Route path=":blogId" element={<Blog />} />
          </Route> */}
          <Route path="solutions" element={<Solutions />} />
          {/* Granulation */}
          <Route path="solutions/granulation" element={<Granulation />} />
          <Route path="solutions/granulation/rapid-mixer-granulator" element={<RapidMixerGranulator />} />
          <Route path="solutions/granulation/fluid-bed-processor" element={<FluidBedProcessor />} />
          <Route path="solutions/granulation/blender" element={<Blender />} />
          <Route path="solutions/granulation/vibro-sifter" element={<VibroSifter />} />
          {/* Tableting */}
          <Route path="solutions/tableting" element={<Tableting />} />
          {/* Coating */}
          <Route path="solutions/coating" element={<Coating />} />
          {/* Encapsulation */}
          <Route path="solutions/encapsulation" element={<Encapsulation />} />
          {/* BlisterPackingCartoning */}
          <Route path="solutions/blister-packing-and-cartoning" element={<BlisterPackingCartoning />} />
          {/* LiquidFilling */}
          <Route path="solutions/liquid-filling" element={<LiquidFilling />} />
          <Route path="solutions/liquid-filling/volumetric-liquid-filling" element={<VolumetricLiquidFilling />} />
          <Route path="solutions/liquid-filling/gear-pump-liquid-filling" element={<GearPumpLiquidFilling />} />
          <Route path="solutions/liquid-filling/rinsing-filling-and-capping" element={<RinsingFillingAndCapping />} />
          <Route path="solutions/liquid-filling/time-based-liquid-filling" element={<TimeBasedLiquidFilling />} />
          <Route path="solutions/liquid-filling/weigh-metric-oil-filling" element={<WeighMetricOilFilling />} />
          <Route path="solutions/liquid-filling/viscous-pneumatic-piston-filling" element={<ViscousPneumaticPistonFilling />} />
          <Route path="solutions/liquid-filling/volumetric-liquid-vial-filling-with-rubber-stoppering" element={<VolumetricLiquidVialFilling />} />
          <Route path="solutions/liquid-filling/single-head-tube-filling" element={<SingleHeadTubeFilling />} />
          <Route path="solutions/liquid-filling/double-head-tube-filling" element={<DoubleHeadTubeFilling />} />
          <Route path="solutions/liquid-filling/semi-automatic-tube-filling" element={<SemiAutomaticTubeFilling />} />
          {/* ChangeParts */}
          <Route path="solutions/change-parts-and-spare-parts" element={<ChangeParts />} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
};

export default Routers;
