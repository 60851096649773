import React, { useState } from "react";
import { Container, Image, Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { granulation, granulationFluid, granulationFluidTopSpray, granulationFluidTopSpraying, granulationFluidBottomSpray } from "assets";
import { ShareCard, FeaturePoints } from "components";
import { useMediaQuery } from "react-responsive";


const FluidBedProcessor = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;
    const [topSpray, setTopSpray] = useState(true);

    const keyFeatures = [
        "Single Pot technology seamlessly integrates mixing, granulating, and drying processes",
        "Granulation excellence achieved through the Top Spraying system",
        "User-friendly Color HMI 7\" interface for intuitive operation",
        "Various batch size capacity models cater to diverse production needs",
        "Advanced PLC controls for precision in the granulation system",
        "Atomized spraying gun and high-precision flow peristaltic pump ensure optimal spraying",
        "Convenient sampling port for withdrawal of samples during the process",
        "Pneumatic sealing of Filter Bag and Product container with inflatable silicon tube (food grade)",
        "Inlet Air handler equipped with Micro and HEPA Air Filters and Heat Exchanger",
        "21 CFR Part 11 compliance available on request for regulatory adherence"
    ]

    const optionalEnhancements = [
        "Air velocity sensor for precise control",
        "Exhaust air filter section with Eu4 filter for enhanced air filtration",
        "Inlet and outlet duct for optimized airflow",
        "Pneumatic finger bag lifting and lowering for efficient handling",
        "Flame Proof (FLP) construction for added safety in specific environments",
        "A.C. driving for exhaust blower for reliable performance",
        "Sound relaxation silencer for blower for quieter operation",
        "Additional SS-316L bowl and SS-304 trolley for increased capacity",
        "Wet In-Place (WIP) system for easy cleaning and maintenance",
        "Extra PC satin finger bag for extended use",
        "Upgraded Color HMI 10\" for an enhanced user interface"
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={granulation}
                    alt="Granulation"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Granulation</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Fluid Bed Processor</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-lg-5 pt-lg-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/granulation">
                            Granulation
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Fluid Bed Processor
                        </span>
                    </div>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={granulationFluid}
                                alt="Fluid Bed Processor Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                The unparalleled capabilities of our Fluid Bed Processor (RMG) are ideal for
                                processes requiring seamless mixing, precise granule size control, and significant
                                time savings. The dynamic interplay of Mixing Blades and Chopper Blades, meticulously
                                designed with the right profiles and speeds, guarantees uniformly mixed granules
                                of active ingredients and excipients, seamlessly integrated with binders. Achieve
                                precise dose distribution effortlessly through an automated binder addition system,
                                paired with optimal speed configurations for the Mixing and Chopper Blades.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Enhancements :</h3>
                    <FeaturePoints items={optionalEnhancements} />
                    <h3 className="mt-5">Top Spraying & Bottom Spraying System for agglomeration and granulation process :</h3>
                    <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                        <Card className="p-2 d-flex flex-row border-0 bg-tertiary" style={{ borderRadius: 40 }}>
                            <Button
                                className={`border-0 manrope-600 ${topSpray ? "bg-primary text-tertiary" : "bg-transparent text-primary"} ${large ? "" : "w-100"}`}
                                style={{
                                    paddingLeft: topSpray ? 40 : 20,
                                    paddingRight: topSpray ? 40 : 20,
                                    borderRadius: 25,
                                    height: 50,
                                }}
                                onClick={() => {
                                    setTopSpray(true)
                                }}
                            >
                                Top Spray
                            </Button>
                            <Button
                                className={`border-0 manrope-600 ${topSpray ? "bg-transparent text-primary" : "bg-primary text-tertiary"} ${large ? "" : "w-100"}`}
                                style={{
                                    paddingLeft: topSpray ? 20 : 40,
                                    paddingRight: topSpray ? 20 : 40,
                                    borderRadius: 25,
                                    height: 50,
                                }}
                                onClick={() => {
                                    setTopSpray(false)
                                }}
                            >
                                Bottom Spray
                            </Button>
                        </Card>
                    </div>
                    {topSpray ?
                        <>
                            <Row>
                                <Col sm={12} md={4} lg={4}>
                                    <Image
                                        className="w-100"
                                        src={granulationFluidTopSpray}
                                        alt="Top Spraying"
                                        style={{ borderRadius: 20 }}
                                    />
                                </Col>
                                <Col sm={12} md={8} lg={8}>
                                    <div className="subtitle text-gray mt-4">
                                        The fluid bed process is a dynamic technique employed to transform
                                        fine powder into cohesive granules within fluidized beds. This method
                                        involves wetting the fluidized powder with a binder through a top spraying
                                        system, equipped with a spraying nozzle and precise dosing peristaltic
                                        pump. The powder is wetted until liquid bridges form between particles.
                                        Notably, segregation is minimized as different powders bond on a micro
                                        scale without impinging forces, resulting in weak yet highly soluble granules.
                                    </div>
                                    <div className="subtitle text-gray mt-4">
                                        After granule formation, the next step involves drying the granules within
                                        the equipment using hot air. This technology is particularly valuable for
                                        creating granules tailored for tableting, capsules, ensuring a dust-free
                                        process, promoting free-flow properties, and enhancing solubility through
                                        the porous structure of the granules.
                                    </div>
                                    <div className="subtitle text-gray mt-4">
                                        Two distinct modes of fluid bed granulation exist: wet stage and dry stage.
                                        In dry stage granulation, particles require minimal wetting to become tacky
                                        and adhere to one another. The granulating solution is applied at a rate equal
                                        to or less than its evaporation rate, maintaining "dry" particles throughout
                                        the process. Conversely, wet stage granulation involves significant moisture or
                                        granulating solution application, surpassing the evaporation rate until particles
                                        accumulate enough moisture for granulation to occur. This versatile process
                                        offers flexibility in adapting to different granulation requirements.
                                    </div>
                                </Col>
                            </Row>
                            <Image
                                className="w-100"
                                src={granulationFluidTopSpraying}
                                alt="Top Spraying Explanation"
                                style={{ borderRadius: 20 }}
                            />
                        </>
                        :
                        <Row>
                            <Col sm={12} md={3} lg={3}>
                                <Image
                                    className="w-100"
                                    src={granulationFluidBottomSpray}
                                    alt="Bottom Spraying"
                                    style={{ borderRadius: 20 }}
                                />
                            </Col>
                            <Col sm={12} md={9} lg={9}>
                                <div className="subtitle text-gray mt-4">
                                    The distinctive features of bottom-spraying include an air distribution plate
                                    and a partition that collaborate to organise the fluidization of particles
                                    through the partition, creating a designated coating zone. Positioned at
                                    the bottom of the product container and centred in the coating zone, the
                                    nozzle plays a key role. The proximity between the coating materials and
                                    particles minimises spray-drying, ensuring high coating uniformity and efficiency.
                                </div>
                                <div className="subtitle text-gray mt-4">
                                    During the coating process, particles are suspended in the air by establishing
                                    a fluidized bed within a heated air stream, and a coating solution is precisely
                                    sprayed onto the particles through a Wurster column. Our specially modified
                                    bottom spray inserts act as a safeguard for heat-sensitive products, preventing
                                    excessive heat exposure while ensuring an even coating on pellets or granules.
                                    Critical design parameters, such as chamber geometry, air velocity, and the mass
                                    flow ratio of air to product, are meticulously matched in our equipment. This
                                    attention to detail ensures optimal performance and consistent results in the
                                    coating process.
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </Container>
        </>
    );
};

export default FluidBedProcessor;
