import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SolutionCard = ({ solution, currentSolution }) => {

    const navigate = useNavigate();

    return (
        <Card
            onClick={() => navigate(`${solution.slug}`)}
            className={`d-flex justify-content-center ${currentSolution === solution.slug ? 'enlarged-solution-card' : 'solution-card cursor-pointer'}`}
            style={{ borderRadius: 20, height: 360 }}
        >
            <Image src={solution.image} alt={solution.title} style={{ borderRadius: 20 }} />
            <div
                className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: 20 }}
            >
            </div>
            <div className="mx-4 position-absolute text-white" style={{ bottom: 0 }}>
                <h3 className="py-3">{solution.title}</h3>
            </div>
        </Card>
    )
}

export default SolutionCard;