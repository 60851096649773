import React from "react";
import "./App.css";
import "./App.scss";
import { BreakpointProvider } from "react-socks";
import Routers from "./Routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// persistor.purge();

const App = () => {
  // setDefaultBreakpoints([
  //   { xsmall: 0 }, // all mobile devices
  //   { small: 576 }, // mobile devices (not sure which one's this big)
  //   { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  //   { large: 992 }, // smaller laptops
  //   { xlarge: 1200 }, // laptops and desktops
  //   { xxlarge: 1400 }, // laptops and desktops
  // ]);

  return (
    <BreakpointProvider>
      <ToastContainer />
      <Routers />
    </BreakpointProvider>
  );
};

export default App;
