import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Image, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  blisterPackingSquare,
  coatingSquare,
  encapsulationSquare,
  granulationSquare,
  liquidFillingSquare,
  solutionsWeOffer,
  sparePartsSquare,
  tabletingSquare,
} from "assets";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const AllSolutions = () => {

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesToShow: large ? 3 : 1,
    slidesToScroll: 1
  };

  const solutions = [
    {
      id: 1,
      title: "Granulation",
      slug: "granulation",
      image: granulationSquare,
    },
    {
      id: 2,
      title: "Tableting",
      slug: "tableting",
      image: tabletingSquare,
    },
    {
      id: 3,
      title: "Coating",
      slug: "coating",
      image: coatingSquare,
    },
    {
      id: 4,
      title: "Encapsulation",
      slug: "encapsulation",
      image: encapsulationSquare,
    },
    {
      id: 5,
      title: "Blister Packing & Cartoning",
      slug: "blister-packing-and-cartoning",
      image: blisterPackingSquare,
    },
    {
      id: 6,
      title: "Liquid Filling",
      slug: "liquid-filling",
      image: liquidFillingSquare,
    },
    {
      id: 7,
      title: "Change Parts & Spare Parts",
      slug: "change-parts-and-spare-parts",
      image: sparePartsSquare,
    },
  ];

  return (
    <Container
      fluid
      className="my-5 user-select-none"
    >
      <div className="custom-header mb-3 text-center">Our Solutions</div>
      <div className="subtitle-400 text-tertiary text-center mb-5">
        Your needs, our expertise. Find the perfect solution
      </div>
      <Row>
        {large && <Col lg={3} className="ps-3">
          <Card className="h-100 d-flex justify-content-center align-items-center bg-tertiary" style={{ borderRadius: 20 }}>
            <Image src={solutionsWeOffer} alt="Solutions for All" style={{ width: "50%" }} />
            <h3 className="manrope-600 mb-3 mt-5 text-primary">Solutions offered</h3>
          </Card>
        </Col>}
        <Col lg={large ? 9 : 12}>
          <Row>
            <Slider ref={slider => { sliderRef = slider; }} {...settings}>
              {solutions.map((item) => (
                <Col className="pe-3" lg={4}>
                  <a href={`solutions/${item.slug}`}>
                    <Card style={{ borderRadius: 20 }}>
                      <Image src={item.image} alt={item.title} style={{ borderRadius: 20 }} />
                      <div
                        className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: 20 }}
                      >
                      </div>
                      <div className="mx-4 position-absolute text-white" style={{ bottom: 0 }}>
                        <h3 className="py-3">{item.title}</h3>
                      </div>
                    </Card>
                  </a>
                </Col>
              ))}
            </Slider>
          </Row>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <div
          className="d-flex text-primary my-2 my-lg-4 cursor-pointer bg-tertiary border border-primary"
          style={{
            textDecoration: "none",
            padding: "12px",
            backgroundColor: "#1B1E29",
            borderRadius: "25px",
          }}
          onClick={previous}
        >
          <IoIosArrowBack size={20} />
        </div>
        <div
          className="d-flex text-primary ms-3 my-2 my-lg-4 cursor-pointer bg-tertiary border border-primary"
          style={{
            textDecoration: "none",
            padding: "12px",
            backgroundColor: "#1B1E29",
            borderRadius: "25px",
          }}
          onClick={next}
        >
          <IoIosArrowForward size={20} />
        </div>
      </div>
    </Container>
  )

}

export default AllSolutions