import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, semiAutomaticTubeFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const SemiAutomaticTubeFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Tube Cooling System: Incorporates a water pump to circulate water around the tube, enhancing the sealing quality by cooling.",
        "Quick Changeover: Enables swift transition from filling aluminium tubes to lami tubes, ensuring operational efficiency.",
        "Tail-Free Filling: Ensures no tails are left after the filling process, contributing to a clean and precise operation.",
        "Quality Material Construction: All contact parts crafted from high-quality materials, offering options such as SS 304, 316, or 316L based on your requirements.",
        "Production Counter: Features a production counter on the control panel, providing a clear indication of the number of tubes filled for efficient monitoring.",
        "Low Maintenance: Designed for lower maintenance requirements, ensuring prolonged and hassle-free operation.",
        "Automatic Tube Ejection: Streamlines the process with automatic tube ejection, enhancing operational efficiency and reducing manual intervention."
    ]

    const optionalFeatures = [
        "Jacketed Hopper with Stirrer: Adds the option of a jacketed hopper with a stirrer, enhancing temperature control and material consistency during the filling process.",
        "Tube Orientation with I Mark: Includes the feature of tube orientation with an I mark, ensuring precise alignment for accurate filling and coding.",
        "Increased Filling Capacity: Offers the flexibility to increase filling capacity to 300 gm, catering to a wider range of product requirements."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Semi-Automatic Tube Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-lg-5 pt-lg-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Semi-Automatic Tube Filling
                        </span>
                    </div>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={semiAutomaticTubeFilling}
                                alt="Semi-Automatic Tube Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                This tube filling machine is a comprehensive solution for a variety of materials
                                and applications. The incorporation of auto and semi-auto tube filling machines,
                                capable of handling high viscosity materials such as paint, adhesive, cosmetics,
                                lotion, toothpaste, shampoo, demonstrates its versatility.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The construction of the machine body using SS pipes and plates, painted to a smooth
                                finish and covered with stainless steel, ensures durability and cleanliness. The use
                                of Stainless Steel 304 for filling area contact parts, including the hopper, adds to
                                the quality and hygiene of the machine.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The mechanical drive with a positive suction pump assembly for the filling mechanism,
                                along with the provision of different syringes for various fill volumes, showcases
                                the adaptability of the machine. The incorporation of no-tube-no-filling sensors
                                connected to a pneumatic cylinder ensures precise and efficient filling.
                            </div>
                        </Col>
                    </Row>
                    <div className="subtitle text-gray mt-4">
                        The heating process with a standard heater, capable of reaching temperatures up to 700°C with
                        constant airflow, highlights the precision in sealing. The mechanism of lifting the tube inside
                        the heater for targeted heating without affecting the filled material is a thoughtful design
                        choice. The sealing and trimming processes with pneumatic jaws and blades contribute to a
                        smooth and efficient finishing touch.
                    </div>
                    <div className="subtitle text-gray mt-4">
                        Overall, your machine seems well-equipped to handle a diverse range of materials and deliver
                        reliable performance in the tube filling process.
                    </div>
                    <h3 className="mt-4">Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Features :</h3>
                    <FeaturePoints items={optionalFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default SemiAutomaticTubeFilling;
