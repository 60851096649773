import React from "react";

const CardBulletPoint = ({ data }) => {

    return (
        <div className="inidividual-bullet-point-container rows-cols-2" style={{ height: "100%" }}>
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <h5 className="text-center my-2" style={{ overflowWrap: "anywhere" }}>{data.title}</h5>
                <span style={{ textAlign: "center", overflowWrap: "anywhere" }}>{data.description}</span>
            </div>
        </div>
    );
}

export default CardBulletPoint;