import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { spareChangePartsMachine, spareParts } from "assets";
import { FeaturePoints, ShareCard } from "components";

const ChangeParts = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const points = [
        <div>
            <b>Type of Tools & Dies:</b>
            <span> TSM & Euro Standard.</span>
        </div>,
        <div>
            <b>Material:</b>
            <span> OHNS, S7, ESR S7, D2, 440C, M2 & D3.</span>
        </div>,
        <div>
            <b>Types of Coating:</b>
            <span> HCP, CrN, MCrN, TiN, & DLC</span>
        </div>,
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={spareParts}
                    alt="Change Parts & Spare Parts"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Change Parts & Spare Parts</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "ms-4" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="my-lg-5 pt-lg-5">
                        <h3 className="text-tertiary">Introduction</h3>
                        <div className="bg-primary" style={{ width: 100, height: 4 }} />
                        <div className="subtitle text-gray mt-4">
                            We are providing range of Change parts for all types Capsule filling machines &
                            Format parts for all types of Blister packing & cartooning machines.
                        </div>
                        <h4 className="text-tertiary mt-4">Tablet tooling for all type of Tablet press :</h4>
                        <div className="subtitle text-gray mt-3">
                            We are Manufacturer and Exporter of Tablet Tools, Dies, Tablet Press Spare Parts,
                            Inspection Kits, Polishing Kits and Tool Storage Cabinets required for Pharmaceutical,
                            Nutritional, Veterinary and Confectionery Industries.
                        </div>
                        <h4 className="text-tertiary mt-4">Punch & Dies :</h4>
                        <div className="subtitle text-gray mt-3">
                            A set of Standard and Non-Standard tooling consists of Upper Punch, Lower Punch and Die
                            usually made from Steel which helps in producing different shapes and size of tablets.
                            We can help you select the right Type of Tooling by referring your tablet specifications
                            and formulation type.
                        </div>
                        <h4 className="text-tertiary mt-4">Multitip & Monotip :</h4>
                        <ul className="subtitle text-gray mt-3">
                            <li>Reduces production time but also increases the production output per minute.</li>
                            <li>Ensures sustainability and better resistance against high pressure.</li>
                        </ul>
                        <FeaturePoints items={points} variant={true} />
                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <h4 className="text-tertiary mt-4">Inspection Kit :</h4>
                                <div className="subtitle text-gray mt-3">
                                    Inspection Kit is used to Measure Total Length, Working Length, Tip to Body
                                    Concentricity, Die Outer Diameter, Die Bore, Die Height etc.
                                </div>
                                <div className="subtitle text-gray mt-3">
                                    As continuous usage of Punch & Die on the Tableting Machine causes difficulties
                                    like-Weight, Thickness and hardness variation of Tablets. Therefore, time to
                                    time inspection of Punch & Die is necessary to ensure that Tooling dimensions
                                    stays in their tolerance range.
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-center align-items-center">
                                <div className="mt-4">
                                    <ShareCard share_url={share_url} bottom={false} />
                                </div>
                                <Image
                                    src={spareChangePartsMachine}
                                    alt="Tableting Machine"
                                    style={{ borderRadius: 20, width: 410, height: 410 }}
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default ChangeParts;
