import React from "react";
import {
  Button,
  Container,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  AllSolutions,
  AboutUsSection,
} from "components";
import {
  homeHeroBackground,
  gemtechLogo,
} from "assets";
import { useMediaQuery } from "react-responsive";

const Home = () => {
  const navigate = useNavigate();
  const blogs = [];
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <>
      <Container fluid className="background-video-container">
        <Container
          className={`d-flex flex-column justify-content-center align-items-center ${large ? "" : "mt-5 pt-5"}`}
          style={{ minHeight: "100vh" }}
        >
          <video autoPlay loop muted className="background-video">
            <source src={homeHeroBackground} type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <div className="text-center w-100">
            <Image
              className={`animated-gemtech-logo ${large ? "mt-4" : "mt-5"}`}
              src={gemtechLogo}
              style={large ? { maxHeight: 350 } : { maxHeight: 250 }}
            />
          </div>
          <div
            className={`home-heading text-white mt-2 mb-3 text-center`}
            style={large ? { paddingLeft: 150, paddingRight: 150 } : {}}
          >
            <span className="manrope-600">GEMTECH</span>
            <span className="manrope-200"> Solutions </span>
            {/* <span className="manrope-600"></span> */}
          </div>
          <div
            className={`subtitle-400 text-white ${large ? "text-center mb-3" : "mb-5"
              }`}
            style={large ? { paddingLeft: 235, paddingRight: 235 } : {}}
          >
            In the heart of India
          </div>
          <div className="text-center w-100">
            <Button
              style={styles.exploreButton}
              onClick={() => navigate(`/solutions`)}
            >
              <span className="manrope-600 text-white">Our Solutions</span>
            </Button>
          </div>
        </Container>
      </Container>
      <AboutUsSection />
      <AllSolutions />
      {/* <Breakpoint large up>
        <Container
          className="d-flex flex-column align-items-center px-lg-5 py-5 mb-5"
          fluid
        >
          <div className="custom-header mb-3">Blogs</div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            Interviews, tips, guides, industry best practices, and news.
          </div>
          <Container>
            <Row className="d-flex row-cols-2">
              {blogs.map((blog) => {
                return (
                  <Col>
                    <Card
                      className="overflow-hidden d-flex justify-content-center align-items-center p-0 m-0"
                      style={{ borderRadius: "20px", background: "#eff0fa", height: 400 }}
                    >
                      <a className="w-100 p-0" href={`/blogs/${blog?.slug}`}>
                        <Image
                          // className="w-100"
                          src={blog?.blog_image}
                          alt={blog?.title}
                          style={{ minHeight: 400, width: "auto" }}
                        />
                      </a>
                    </Card>
                    <h4 className="my-3">{blog?.title}</h4>
                    <div className="mb-5">{blog?.description}</div>
                  </Col>
                );
              })}
            </Row>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className="bg-secondary text-primary primary-button border border-primary"
                onClick={() => {
                  navigate(`/blogs`);
                }}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 30,
                  height: 60,
                }}
              >
                View All
              </Button>
            </div>
          </Container>
        </Container>
      </Breakpoint> */}
    </>
  );
};

const styles = {
  milestoneButton: {
    backgroundColor: "white",
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 16,
    border: "2px solid",
  },
  exploreButton: {
    paddingLeft: 45,
    paddingRight: 45,
    borderRadius: 55,
    height: 70,
  },
};

export default Home;
