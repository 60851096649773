import React, { useState } from 'react';
import { FiCopy } from "react-icons/fi";
import { FaLinkedin, FaSquareFacebook, FaSquareXTwitter } from "react-icons/fa6";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { Button, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoLogoWhatsapp } from 'react-icons/io';

const ShareCard = ({ share_url, bottom = true }) => {

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(share_url).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? 'Copied!' : 'Copy link'}
        </Tooltip>
    );

    return (
        <Container
            className={`user-select-none ${bottom ? "mb-5 pb-5" : ""}`}
            style={{ maxWidth: 840 }}
        >
            <div className="d-flex justify-content-center">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <Button
                        className="bg-white text-black manrope-700 me-1"
                        onClick={copyToClipboard}
                        style={{
                            paddingLeft: 30,
                            paddingRight: 30,
                            border: "1px solid #D0D5DD",
                            borderRadius: 30,
                            height: 48,
                        }}
                    >
                        <FiCopy className="me-1" />
                        <span>Copy Link</span>
                    </Button>
                </OverlayTrigger>
                <WhatsappShareButton url={share_url}>
                    <span
                        className="d-flex justify-content-center align-items-center border mx-1"
                        style={{ width: 48, height: 48, borderRadius: 24 }}
                    >
                        <IoLogoWhatsapp size={24} color="#25d366" />
                    </span>
                </WhatsappShareButton>
                <LinkedinShareButton url={share_url}>
                    <span
                        className="d-flex justify-content-center align-items-center border mx-1"
                        style={{ width: 48, height: 48, borderRadius: 24 }}
                    >
                        <FaLinkedin size={24} color="#0a66c2" />
                    </span>
                </LinkedinShareButton>
                <TwitterShareButton url={share_url}>
                    <span
                        className="d-flex justify-content-center align-items-center border mx-1"
                        style={{ width: 48, height: 48, borderRadius: 24 }}
                    >
                        <FaSquareXTwitter size={24} color="#000000" />
                    </span>
                </TwitterShareButton>
                <FacebookShareButton url={share_url}>
                    <span
                        className="d-flex justify-content-center align-items-center border mx-1"
                        style={{ width: 48, height: 48, borderRadius: 24 }}
                    >
                        <FaSquareFacebook size={24} color="#0866ff" />
                    </span>
                </FacebookShareButton>
            </div>
        </Container>
    )
}

export default ShareCard;