import React, { useState } from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, rinsingFillingAndCapping } from "assets";
import { ShareCard } from "components";
import { useMediaQuery } from "react-responsive";
import { FaAnglesUp, FaAnglesDown } from "react-icons/fa6";


const RinsingFillingAndCapping = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;
    const [showAll, setShowAll] = useState(false);


    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Rinsing Filling And Capping</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "w-100 my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/get-in-touch`)}
                        >
                            Get In Touch
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-lg-5 pt-lg-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Rinsing Filling And Capping
                        </span>
                    </div>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={rinsingFillingAndCapping}
                                alt="Rinsing Filling And Capping Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                Discover the Automatic Rotary Rinsing, Filling, and Capping Machine—an all-in-one,
                                fully automated, multi-functional marvel that seamlessly combines the processes of
                                washing, filling, and capping within a single unit. This versatile machine is designed
                                to efficiently package not only mineral water but also various beverages like fruit
                                juice and squash, even those filled at higher temperatures. With its advanced
                                man-machine interface control and automation features, this machine ensures a
                                user-friendly experience. Utilizing the hanging neck holding method, it provides
                                utmost convenience for swift bottle changeovers.
                            </div>
                        </Col>
                    </Row>
                    <h3>Bottle In-Feed & Rinsing Section :</h3>
                    <div className="subtitle text-gray mt-4">
                        The in-feed star wheel is equipped with spring-loaded neck gripper fingers,
                        providing adaptability to minor variations in bottle-neck diameter. Bottles
                        conveyed by the air conveyor are securely grasped by a fixed-size neck star
                        wheel and seamlessly transferred between the grip fingers. This innovative
                        design eliminates the need for a feed worm and the hassle of changing parts
                        for different bottle body diameters.
                    </div>
                    <div className="subtitle text-gray mt-4">
                        The neck spider then efficiently conveys the bottles to the rinsing turret,
                        featuring neck-gripping fingers that guide the bottles through the rinsing
                        process. The spray nozzles incorporate a "No Bottle – No Rinsing" spray mechanism, p
                        romoting water conservation. Rinsing occurs during a 90-degree rotation of
                        the turret. Subsequently, for the next 90 degrees, the bottles are gently
                        tilted back at a slight angle to allow for optimal draining time.
                    </div>
                    {showAll &&
                        <>
                            <h3 className="mt-5">Bottle Transfer & Filling Section :</h3>
                            <div className="subtitle text-gray mt-4">
                                After undergoing thorough rinsing and drainage, the bottles are once again
                                efficiently picked up by the star wheel and conveyed to the filling lifter
                                gripper platform. The filling lifters operate on the positive cam lift-off
                                principle, eliminating the need for compressed air or spring load. The lift
                                cylinder cam, crafted from stainless steel with a fine finish, follows a simple
                                harmonic motion profile, ensuring a smooth lift for the bottles and precise
                                filling valve engagement.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The filling valve operates on a gravity filling system with an added AIR EXHAUST
                                mechanism, effectively expelling stagnant air from the bottle to the outside
                                atmosphere. At the top of the filling bowl/tank, a pneumatically operated
                                filling valve is fitted to a single-line pipe, which can be directed outside
                                the filling room. To maintain liquid levels in the bowl, an electronic level
                                controller is integrated, regulating the process through a pneumatic ON-OFF valve.
                            </div>
                            <h3 className="mt-5">Plastic Screw Capper :</h3>
                            <div className="subtitle text-gray mt-4">
                                A foolproof No Bottle No Cap interlock is incorporated into the cap delivery star
                                wheel. Non-contact infrared sensors efficiently detect bottles, prompting an instant
                                halt to cap delivery. The capping chucks, crafted with precision and the finest
                                quality materials, feature a magnetic torque control mechanism, mitigating heat
                                generation typical of friction-based coupling in chucks.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Enhancing adaptability, a flexible segmented cap holder adeptly retrieves caps,
                                compensating for minor variations in cap diameter resulting from prolonged storage.
                                Adjusting capping torque is a swift process, thanks to the magnetic torque control
                                mechanism, and once set, the torque is reliably maintained. The capping turret
                                employs a Pick N Place type cap pick-up mechanism, seamlessly delivering caps to
                                the cap holders of the capping chucks.
                            </div>
                            <h3 className="mt-5">Cap Elevator cum Hopper :</h3>
                            <div className="subtitle text-gray mt-4">
                                A meticulously designed elevator cum selector hopper, available as an option, boasts
                                unique features for enhanced functionality. Positioned at floor level, this hopper
                                accommodates up to 5000 caps. Bucket-type fingers efficiently elevate the caps,
                                allowing caps in the correct direction to proceed to the delivery chute, while
                                those in the wrong direction are redirected to the main hopper.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                For added hygiene, the cap delivery chute can be equipped with optional ozonized
                                water rinse and a clean air jet blow to eliminate water residue. Upon request, an
                                inline Ultraviolet cleaning system can be integrated for thorough cleaning. The
                                chute incorporates high-level and low-level infrared sensors, ensuring precision
                                in cap handling. The elevator motor initiates on a low-level signal, while the
                                high-level motor halts, ensuring seamless operation.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Safety is paramount, with solenoid valves interlocking the cap rinse water line
                                and clean blow airline with the main motor. In the event of a machine stoppage,
                                both solenoid valves promptly close, discontinuing the supply for optimal safety
                                measures.
                            </div>
                            <h3 className="mt-5">Lubrication System :</h3>
                            <div className="subtitle text-gray mt-4">
                                Ensuring smooth operation and minimal maintenance, a centralized lubrication system
                                is integrated, providing consistent oil supply to essential moving components.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Additionally, strategically placed manual grease points are conveniently grouped for
                                easy access, streamlining the lubrication process and promoting operational efficiency.
                            </div>
                        </>
                    }
                    <div className="text-center mt-3">
                        <Button
                            className="bg-white border-0"
                            onClick={() => setShowAll(!showAll)}
                        >
                            {showAll ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="subtitle me-2">Read Less</span>
                                    <FaAnglesUp size={15} />
                                </div>
                                :
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="subtitle me-2">Read More</span>
                                    <FaAnglesDown size={15} />
                                </div>
                            }
                        </Button>
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default RinsingFillingAndCapping;
